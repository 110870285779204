import { Create, FormTab, TabbedForm, TextInput } from "react-admin"

export const QuestionSourceTypeCreate = (props) => {

    return (
        <Create {...props}>
            <TabbedForm redirect={'list'}>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label="Nome" fullWidth />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}