import React, { useContext } from "react";
import { ArrayField, AutocompleteInput, BooleanField, ChipField, Datagrid, DateField, List, ReferenceInput, SearchInput, SelectInput, SingleFieldList, TextField, useRedirect } from "react-admin";
import { AppContext } from '../../providers/app-context-provider/app.context';
const SchoolActivityList = (props) => {

    const redirect = useRedirect()
    const context = useContext(AppContext)
    const superAdmin = context.currentUser.superAdmin
    const profile = context.currentUser.profiles[0]

    const institutionFilter = (
        <ReferenceInput label="Instituição" source="institution" reference="institutions" filterToQuery={(text) => ({ "institution.name": text })} sort={{ field: 'institution.name', order: 'ASC' }}>
            <AutocompleteInput
                source="name"
                emptyText="Todas"
                fullWidth
            />
        </ReferenceInput>
    )

    const listFilters = [
        <SearchInput placeholder="Nome" source="name" alwaysOn />,
        <SelectInput label="Status" source="has_started" choices={[{ name: "Em andamento", id: "true" }, { name: "Não iniciadas", id: "false" }]} alwaysOn />,
        <ReferenceInput label="Área" source="theme" reference="themes" sort={{ field: "name", order: "ASC" }} >
            <AutocompleteInput
                source="name"
                emptyText="Todos"
                fullWidth
            />
        </ReferenceInput>
    ]

    if (superAdmin) {
        listFilters.push(institutionFilter)
    }

    return (
        <List
            exporter={false}
            bulkActionButtons={false}
            sort={{ field: 'end_date', order: 'DESC' }}
            filter={(superAdmin || profile.name !== 'Professor') ? null : { "teacher.id": context.currentUser.id }}
            filters={listFilters}
            filterDefaultValues={{ has_started: true }}

            {...props}
        >
            <Datagrid rowClick={(id, basepath, record) => redirect(`/school-activity-dashboard/${id}`)}>
                <TextField source="name" label="Nome" />
                <TextField source="class.name" label="Turma" />
                <TextField source="teacher.name" label="Professor" />
                {superAdmin && <TextField source="institution.name" label="Instituição" />}
                <ArrayField source="themes" label={"Áreas de conhecimento"}><SingleFieldList linkType={false}><ChipField source="name" /></SingleFieldList></ArrayField>
                <BooleanField source="has_started" label="Em andamento?" />
                <DateField source="start_date" label="Data de início" emptyText="-" />
                <DateField source="end_date" label="Término previsto" emptyText="-" />
            </Datagrid>
        </List>
    )
}

export default SchoolActivityList