import { Datagrid, List, TextField } from "react-admin"

export const QuestionSourceTypeList = (props) => {

    return (
        <List
            exporter={false}
            bulkActionButtons={false}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label="Nome" fullWidth />
            </Datagrid>
        </List>
    )
}