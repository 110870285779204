import { Dialog, DialogContent, DialogTitle, Button as MuiButton } from "@material-ui/core"
import { Edit as EditIcon } from "@material-ui/icons"
import axios from "axios"
import { useState } from "react"
import {
    ArrayField, AutocompleteInput, BooleanField, BooleanInput, Button, Datagrid, Edit, FormTab,
    ListButton, ReferenceInput, SaveButton, SelectInput, SimpleForm, TabbedForm, TextField,
    TextInput, Toolbar, TopToolbar,
    useNotify,
    useRefresh
} from "react-admin"
import { Spacer } from "../../components/spacer"
import cookiesProvider from "../../providers/cookies-provider"
import { getApiUrl } from "../../utils/urls"

export const QuestionEdit = (props) => {

    const EditActions = () => {
        return (
            <TopToolbar>
                <ListButton variant="contained" label="Retornar" />
                <div style={{ flexGrow: 1 }} />
            </TopToolbar>
        )
    }

    const EditQuestionAlternative = () => {

        const [open, setOpen] = useState(false)
        const [loading, setLoading] = useState(false)

        const apiUrl = getApiUrl()
        const refresh = useRefresh();
        const notify = useNotify();

        const handleClose = () => { if (!loading) setOpen(false) }
        const handleOpen = () => setOpen(true)

        const handleSubmit = async (values) => {
            setLoading(true)
            const token = cookiesProvider.getToken();
            await axios.patch(`${apiUrl}/question-alternatives/${values.id}`,
                { id: values.id, text: values.text, correct: values.correct },
                { headers: { "Authorization": token } })
                .then(response => {
                    if (response.status === 500 || response.status === 400) {
                        notify('Erro', 'error')
                    } else {
                        notify('Alternativa alterada com sucesso.', 'success')
                        refresh()
                    }
                })
                .catch((error) => { notify(error, 'error') })
                .finally(() => setLoading(false))

            setOpen(false);
        }

        const ToolbarForm = (props) => {
            return <Toolbar {...props} >
                <MuiButton
                    variant={'contained'}
                    onClick={handleClose}
                    disabled={loading}
                >
                    Cancelar
                </MuiButton>
                <Spacer />
                <SaveButton
                    disabled={loading}
                    label="Salvar"
                    submitOnEnter={false}
                    onSave={(values) => handleSubmit(values)}
                />
            </Toolbar>
        }

        return (
            <>
                <Button variant="contained" label="Editar" startIcon={<EditIcon />} onClick={handleOpen} />
                <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                    <DialogTitle>{'Editar alternativa'}</DialogTitle>
                    <DialogContent>
                        <SimpleForm toolbar={<ToolbarForm />}>
                            <TextInput source="id" hidden disabled />
                            <TextInput source="text" label="Texto" multiline minRows={2} fullWidth />
                            <BooleanInput source='correct' label="Correta?" defaultValue={false} fullWidth />
                        </SimpleForm>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    return (
        <Edit mutationMode="pessimistic" actions={<EditActions />} {...props}>
            <TabbedForm redirect={'list'}>
                <FormTab label={'Dados Gerais'}>
                    <ReferenceInput reference="question-sources" source="source.id" label="Fonte" fullWidth>
                        <SelectInput source="name" fullWidth />
                    </ReferenceInput>
                    <ReferenceInput reference="themes" source="theme.id" label="Área do direito" sort={{ field: 'name', order: 'ASC' }} fullWidth>
                        <AutocompleteInput source="name" fullWidth />
                    </ReferenceInput>
                    <TextInput source="number" label="Número" fullWidth />
                    <BooleanInput source="status" label="Ativa?" />
                    <TextInput source="text" label="Enunciado" multiline minRows={3} fullWidth />
                    <ArrayField source='alternatives' label="Alternativas" fullWidth>
                        <Datagrid>
                            <TextField source="text" label="Texto" multiline minRows={2} fullWidth />
                            <BooleanField source='correct' label="Correta?" defaultValue={false} fullWidth />
                            <EditQuestionAlternative />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}