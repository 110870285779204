import { Edit, FormTab, ReferenceInput, SelectInput, TabbedForm, TextInput } from "react-admin"

export const QuestionSourceEdit = (props) => {

    return (
        <Edit mutationMode="pessimistic" {...props}>
            <TabbedForm redirect={'list'}>
                <FormTab label={'Dados Gerais'}>
                    <ReferenceInput reference="question-source-types" source="source_type.id" label="Tipo" fullWidth>
                        <SelectInput source="name" fullWidth />
                    </ReferenceInput>
                    <TextInput source="name" label="Nome" fullWidth />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}