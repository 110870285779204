import { Datagrid, List, TextField } from "react-admin"

export const QuestionList = (props) => {



    return (
        <List
            sort={{ field: 'source.name', order: 'ASC' }}
            exporter={false}
            bulkActionButtons={false}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="number" label="Número" />
                <TextField source="source.name" label="Fonte" />
                <TextField source="source.source_type.name" label="Tipo" />
                <TextField source="theme.name" label="Tema" />
            </Datagrid>
        </List>
    )
}