import { Create, FormTab, ReferenceInput, SelectInput, TabbedForm, TextInput } from "react-admin"

export const QuestionSourceCreate = (props) => {

    return (
        <Create {...props}>
            <TabbedForm redirect={'list'}>
                <FormTab label={'Dados Gerais'}>
                    <ReferenceInput reference="question-source-types" source="source_type" label="Tipo" fullWidth>
                        <SelectInput source="name" fullWidth />
                    </ReferenceInput>
                    <TextInput source="name" label="Nome" fullWidth />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}