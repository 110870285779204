import { Datagrid, List, TextField } from "react-admin"

export const QuestionSourceList = (props) => {

    return (
        <List
            exporter={false}
            bulkActionButtons={false}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label="Nome" />
                <TextField source="source_type.name" label="Tipo" />
            </Datagrid>
        </List>
    )
}