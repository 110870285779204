import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import { Add, Close, Delete, Save } from "@material-ui/icons"
import RichTextInput from "ra-input-rich-text"
import { useState } from "react"
import {
    ArrayField,
    AutocompleteInput, BooleanInput, Button, Datagrid, DateTimeInput, DeleteButton, Edit, FormDataConsumer,
    FormTab, ListButton, ReferenceInput, ReferenceManyField, SaveButton, TabbedForm, TextField,
    TextInput, Toolbar, TopToolbar, useNotify, useRecordContext,
    useRedirect,
    useRefresh
} from "react-admin"
import { DashboardButton } from "../../components/dashboard-button"
import MaskedInput from "../../components/masked-input"
import dataProvider from "../../providers/data-provider"
import SchedulingAddUser from "./scheduling-add-manager"
import SchedulingAddStudent from "./scheduling-add-student"
import { Spacer } from "../../components/spacer"

const RemoveClientButton = ({ record, ...rest }) => {

    const refresh = useRefresh();

    const handleClick = () => {
        dataProvider.get('schedulings', `${record.id}/removeClient`).then(() => refresh())
    }

    const disabled = record.process && record.service_request
    return <Button label="Remover cliente" startIcon={<Delete />} variant="contained" onClick={handleClick} disabled={disabled} />
}

const GenerateProcessButton = ({ record, ...props }) => {

    const [loading, setLoading] = useState(false)
    const notify = useNotify()
    const refresh = useRefresh();
    const handleClick = async () => {
        setLoading(true)
        await dataProvider.post('schedulings', `${record.id}/generateProcess`, { id: record.id })
            .then(() => { notify('Atendimento gerado com sucesso', 'success'); }, () => { notify('Erro ao gerar atendimento', 'error') })
            .finally(() => { refresh(); setLoading(false); })
    }

    const valid = !record?.process && !record?.service_request && record?.client //&& record?.students && record?.students?.length > 0

    return (
        <Button
            label="Gerar Atendimento"
            startIcon={loading ? <CircularProgress size={24} /> : <Add />}
            onClick={!loading && handleClick}
            disabled={loading || !valid}
        />
    )

}

const CustomToolbar = props => {
    const record = useRecordContext();
    const notify = useNotify();
    const customFailure = (error) => {
        notify(`Impossível remover agendamento com dados vinculados`, 'error');
    }
    return <Toolbar {...props} >
        <SaveButton disabled={props.pristine} />
        <div style={{ flexGrow: 1 }} />
        {!record?.process && !record?.service_request && <DeleteButton mutationMode="pessimistic" onFailure={customFailure} />}
    </Toolbar>
}

const EditActions = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <ListButton label="Retornar" />
            <div style={{ flexGrow: 1 }} />
            {data?.process ? <DashboardButton record={data} resource={resource} /> :
                <>
                    <GenerateProcessButton record={data} />
                    <ClearRecordButton record={data} />
                </>}
        </TopToolbar>
    )
}


const ClearRecordButton = ({ record, ...props }) => {
    const redirect = useRedirect()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => { setOpen(false) };
    const onSubmit = () => {
        setLoading(true);
        dataProvider.get('schedulings', `${record.id}/clearRecord`).then(() => redirect('/schedulings'))
            .finally(() => setLoading(false))

    }

    return <>
        <Button
            startIcon={<Delete />}
            label="Desmarcar cliente"
            onClick={handleOpen}
            disabled={record?.process && record?.service_request}
        />
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Desmarcar Agendamento</DialogTitle>
            <DialogContent>
                <DialogContentText>Deseja desmarcar o agendamento? Todas as informações preenchidas serão perdidas, exceto o horário cadastrado.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' label="Fechar" color="secondary" onClick={handleClose} disabled={loading}>
                    <Close />
                </Button>
                <Spacer />
                <Button variant='contained' label="Desmarcar" color="primary" onClick={onSubmit} disabled={loading}>
                    {loading ? <CircularProgress size={16} /> : <Save />}
                </Button>
            </DialogActions>
        </Dialog>
    </>

}

export const SchedulingEdit = (props) => {

    const [loading, setLoading] = useState(false)

    const refresh = useRefresh()

    const handleRemoveUser = (schedulingId, userId) => {
        setLoading(true)
        dataProvider.delete(`schedulings/${schedulingId}/user`, { id: userId })
            .then(() => { refresh() })
            .finally(() => setLoading(false))
    }

    const handleRemoveMembership = (schedulingId, membershipId) => {
        setLoading(true)
        dataProvider.delete(`schedulings/${schedulingId}/membership`, { id: membershipId })
            .then(() => { refresh() })
            .finally(() => setLoading(false))
    }
    return (
        <Edit {...props}
            mutationMode="pessimistic"
            actions={<EditActions />}
        >
            <TabbedForm redirect="edit" toolbar={<CustomToolbar />}>
                <FormTab path="" label="Cliente">
                    <FormDataConsumer>
                        {
                            ({ formData, record, ...rest }) => {
                                const hasClient = record.client && record.client.name && record.client.name.length > 0

                                return (
                                    <>
                                        <TextInput label="Nome do cliente" source="client.name" disabled={hasClient} fullWidth />
                                        {
                                            !formData?.client?.no_contact_info && <>
                                                <MaskedInput source="client.phone" onlyNumbers={true} mask={'(99) 99999-9999'} disabled={hasClient} label="Telefone do cliente" fullWidth />
                                                <TextInput label="Email do cliente" source="client.email" disabled={hasClient} fullWidth />
                                            </>
                                        }
                                        <BooleanInput source='client.no_contact_info' label={'Contato não informado'} disabled={hasClient} defaultValue={false} fullWidth />

                                        {hasClient && <RemoveClientButton record={record} />}
                                    </>
                                )
                            }
                        }
                    </FormDataConsumer >
                </FormTab>
                <FormTab path="general" label="Agendamento">
                    <FormDataConsumer>
                        {({ formData, record, ...rest }) => {
                            const hasProcess = record.process !== null && record.process !== undefined
                            return <>
                                <DateTimeInput label="Data do atendimento" source="date" fullWidth disabled={hasProcess} />

                                <DateTimeInput label="Data inicial para canditadura do aluno" source="min_apply_date" fullWidth disabled={hasProcess} />
                                <DateTimeInput label="Data final para canditadura do aluno" source="max_apply_date" fullWidth disabled={hasProcess} />

                                <ReferenceInput label="Tipo de atendimento" source="type.id" reference="process-types" sort={{ field: 'name', order: 'ASC' }} perPage={999} fullWidth disabled={hasProcess}>
                                    <AutocompleteInput label="Tipo de atendimento" source="name" optionText="name" optionValue="id" fullWidth />
                                </ReferenceInput>

                                <TextInput label="Período Letivo" source="academic_period.name" fullWidth disabled />

                                <RichTextInput label="Dados complementares" source="details" helperText='Inclua dados complementares do cliente (contatos adicionais / endereço / observações)' fullWidth configureQuill={(quill) => quill.enable(!hasProcess)} />
                            </>
                        }}
                    </FormDataConsumer>
                </FormTab>
                <HideableFormTab path="managers" label="Gestores" comparison={compareValue}>
                    <ReferenceManyField fullWidth reference="users" target="manager_schedulings.id" label="Gestores">
                        <Datagrid>
                            <TextField source="name" label="Nome" />
                            <FormDataConsumer>
                                {
                                    ({ formData, record, ...rest }) =>
                                        <Button
                                            label="Remover"
                                            onClick={() => handleRemoveUser(formData.id, record.id)}
                                            startIcon={<Delete />}
                                            disabled={formData.process !== null && formData.process !== undefined} />
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {
                            ({ formData, record, ...rest }) =>
                                <SchedulingAddUser
                                    record={record}
                                    resource={'schedulings'}
                                    path={'manager'}
                                    title={`Adicionar gestores ao agendamento`}
                                    refresh={refresh}
                                    disabled={record.process !== null && record.process !== undefined}
                                />
                        }
                    </FormDataConsumer>
                </HideableFormTab>
                <HideableFormTab path="students" label="Alunos" comparison={compareClass}>
                    <TextInput label="Máximo de alunos neste agendamento" source="max_students" disabled fullWidth />
                    <ArrayField fullWidth source="memberships" label="Alunos">
                        <Datagrid>
                            <TextField source="student.name" label="Nome" />
                            <TextField source="class.name" label="Turma" />
                            <FormDataConsumer>
                                {
                                    ({ formData, record, ...rest }) => <Button
                                        label="Remover"
                                        disabled={(formData.process !== null && formData.process !== undefined) || loading}
                                        onClick={() => handleRemoveMembership(formData.id, record.id)}
                                        startIcon={loading ? <CircularProgress size={16} /> : <Delete />} />
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ArrayField>
                    <FormDataConsumer>
                        {
                            ({ formData, record, ...rest }) =>
                                <SchedulingAddStudent
                                    record={record}
                                    resource={'schedulings'}
                                    path={'student'}
                                    title={`Adicionar alunos ao agendamento`}
                                    refresh={refresh}
                                    disabled={record.process !== null && record.process !== undefined}
                                />
                        }
                    </FormDataConsumer>
                </HideableFormTab>
            </TabbedForm>
        </Edit>
    )
}

//comparison is a callback function
//if it evals to true or any truthy value, renders the component
//if it evals to false or any falsy value, doesn't render the component
const HideableFormTab = ({ comparison, ...props }) => {
    const record = useRecordContext(props)
    const res = comparison(record)
    return res ? <FormTab {...props} /> : <></>
}

//comparison callback
const compareValue = (record) => { return record.managers?.length > 0 || record.client }

const compareClass = (record) => { return record.client }