import { Edit, FormTab, TabbedForm, TextInput } from "react-admin"

export const QuestionSourceTypeEdit = (props) => {

    return (
        <Edit mutationMode="pessimistic" {...props}>
            <TabbedForm redirect={'list'}>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label="Nome" fullWidth />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}