import { Badge, IconButton, Tooltip, Typography } from '@material-ui/core'
import { Assignment, AttachFile, Edit, Mail } from '@material-ui/icons'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import { useRedirect } from 'react-admin'
import ExpandableCard from '../../components/expandable-card'
import { DangerousHTMLDiv } from '../../components/dangerousHTMLDiv'

const EditProcessButton = ({ process, ...props }) => {

    const redirect = useRedirect();
    const handleClick = () => {
        redirect(`/processes/${process.id}`)
    }

    return (
        <Tooltip title={'Editar o atendimento'}>
            <IconButton color={'text'} onClick={handleClick}>
                <Edit />
            </IconButton>
        </Tooltip>
    )
}

const ProcessMailButton = ({ process, ...props }) => {

    const redirect = useRedirect()
    const handleClick = () => redirect(`/process-mail/${process.id}`)

    return (
        <Tooltip title={'Correio do atendimento'}>
            <IconButton color={'text'} onClick={handleClick}>
                <Badge max={99} badgeContent={process.mail?.mail_count} color={'default'}>
                    <Mail />
                </Badge>
            </IconButton>
        </Tooltip>
    )
}

export const ProcessInfo = ({ process, isStudent, ...props }) => {

    const redirect = useRedirect()

    const content = (
        <>
            <Typography> Síntese: </Typography>
            <DangerousHTMLDiv html={process.synthesis} />
        </>
    );

    const actions = (
        <>
            <ProcessMailButton process={process} />
            {!isStudent && <EditProcessButton process={process} />}
            <Tooltip title={'Notas do atendimento'}>
                <IconButton
                    onClick={
                        () => {
                            redirect(`/student-process-notes/${process.id}`, undefined, undefined, undefined, { processAlias: process.alias })
                        }}>
                    <Assignment />
                </IconButton>
            </Tooltip>
            <Tooltip title={'Anexar'}>
                <IconButton
                    onClick={
                        () => {
                            redirect('create', '/attachments', {}, {}, { redirectTo: `/process-dashboard/${process.id}`, resource: 'processes', id: process.id, fileSize: 25000000 })
                        }}>
                    <AttachFile />
                </IconButton>
            </Tooltip>
        </>
    );

    return (
        <ExpandableCard
            realce={true}
            title={process.alias}
            content={content}
            action={actions}
            actionTextShow={"Síntese"}
            actionTextHide={"Ocultar"}
        >
            {process.type && (
                <>
                    <Typography component={'div'}> Tipo de processo: <Typography color='textSecondary' component={'span'}> {`${process.type.type} ${process.type.pje ? '- PJE' : ''}`} </Typography></Typography>
                    <Typography component={'div'}> Complemento: <Typography color='textSecondary' component={'span'}> {process.type.name} </Typography></Typography>
                </>
            )}
            {process.legal_process_code && <Typography component={'div'}> Número do processo: <Typography color='textSecondary' component={'span'}> {process.legal_process_code} </Typography></Typography>}
            <Typography component={'div'}> Criado em: <Typography color='textSecondary' component={'span'}> {moment(process.created_at).format('LLL')} </Typography></Typography>
            {process?.serviceRequest?.institution_branch?.id && <Typography component={'div'}> Unidade: <Typography color='textSecondary' component={'span'}> {process.serviceRequest?.institution_branch?.name} </Typography></Typography>}
        </ExpandableCard >
    )
}