import React, { useContext } from "react";
import { ArrayField, AutocompleteInput, BooleanField, ChipField, Datagrid, List, ReferenceInput, SearchInput, SingleFieldList, TextField } from "react-admin";
import { AppContext } from "../../providers/app-context-provider/app.context";
import ReplicateModelActivities from "./replicate-model-activities";

const SchoolModelActivityList = (props) => {

    const context = useContext(AppContext)
    const superadmin = context.currentUser.superAdmin;

    const institutionFilter = (
        <ReferenceInput label="Instituição" source="institution" filterToQuery={(text) => ({ "institution.name": text })} reference="institutions" sort={{ field: 'institution.name', order: 'ASC' }}>
            <AutocompleteInput
                source="name"
                emptyText="Todas"
                clearAlwaysVisible={true}
                fullWidth
            />
        </ReferenceInput>
    )

    const filters = [
        <SearchInput placeholder="Nome" source="name" alwaysOn />,
        <ReferenceInput label="Área" source="theme" reference="themes" sort={{ field: "name", order: "ASC" }}>
            <AutocompleteInput
                source="name"
                emptyText="Todos"
                clearAlwaysVisible={true}
                fullWidth
            />
        </ReferenceInput>
    ];

    if (superadmin) {
        filters.push(institutionFilter)
    }

    return (
        <List
            title={'Atividades matriz'}
            exporter={false}
            bulkActionButtons={superadmin ? <ReplicateModelActivities /> : false}
            sort={{ field: 'name', order: 'ASC' }}
            filters={filters}
            {...props}
        >
            <Datagrid rowClick={(id, basePath, record) => `/model-activity-dashboard/${id}`} >
                <TextField source='name' label={'Nome'} />
                <TextField source='author' label={'Autor'} />
                {superadmin && <TextField source='institution.name' label={'Instituição'} />}
                <ArrayField source="themes" label={"Áreas de conhecimento"}><SingleFieldList linkType={false}><ChipField source="name" /></SingleFieldList></ArrayField>
                <BooleanField source='published' label={'Publicado?'} />
                <TextField source='created_at' label={'Criado em'} />
            </Datagrid>
        </List >
    )
}

export default SchoolModelActivityList