import { ArrayInput, AutocompleteInput, BooleanInput, Create, FormTab, ReferenceInput, SelectInput, SimpleFormIterator, TabbedForm, TextInput } from "react-admin"

export const QuestionCreate = (props) => {

    return (
        <Create {...props}>
            <TabbedForm redirect={'list'}>
                <FormTab label={'Dados Gerais'}>
                    <ReferenceInput reference="question-sources" source="source" label="Fonte" fullWidth>
                        <SelectInput source="name" fullWidth />
                    </ReferenceInput>
                    <ReferenceInput reference="themes" source="theme" label="Área do direito" sort={{ field: 'name', order: 'ASC' }} fullWidth>
                        <AutocompleteInput source="name" fullWidth />
                    </ReferenceInput>
                    <TextInput source="number" label="Número" fullWidth />
                    <TextInput source="text" label="Enunciado" multiline minRows={3} fullWidth />
                    <ArrayInput source='alternatives' label="Alternativas" fullWidth>
                        <SimpleFormIterator>
                            <TextInput source="text" label="Texto" multiline minRows={2} fullWidth />
                            <BooleanInput source='correct' label="Correta?" defaultValue={false} fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
    )
}